<template>
  <div class="landing_page">
    <div class="responsive-container-block big-container">
      <img class="bg-img" id="iq5bf" src="../assets/contact_bg.jpg" />
      <div class="responsive-container-block container">
        <div
          class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12 left-one"
        >
          <div class="content-box">
            <p class="text-blk section-head">Feel free to contact us:</p>
            <p class="text-blk section-subhead">
              General Queries :
              <i
                class="bi bi-telephone-forward-fill"
                style="font-size: 12px"
              ></i>
              91-98112-71916 <br />| Online Queries :
              <i
                class="bi bi-telephone-forward-fill"
                style="font-size: 12px"
              ></i>
              91-98112-71916
            </p>
            <p class="text-blk section-subhead">
              Online Courses :
              <i class="bi bi-envelope-at-fill"></i> support@chestcourses.org
              <br />| General Queries :
              <i class="bi bi-envelope-at-fill"></i> sngaur9@gmail.com
            </p>
            <div class="icons-container">
              <a class="share-icon">
                <img
                  class="img"
                  src="https://workik-widget-assets.s3.amazonaws.com/Footer1-83/v1/images/Icon-twitter.png"
                />
              </a>
              <a class="share-icon">
                <img
                  class="img"
                  src="https://workik-widget-assets.s3.amazonaws.com/Footer1-83/v1/images/Icon-facebook.png"
                />
              </a>
              <a class="share-icon">
                <img
                  class="img"
                  src="https://workik-widget-assets.s3.amazonaws.com/Footer1-83/v1/images/Icon-google.png"
                />
              </a>
              <a class="share-icon">
                <img
                  class="img"
                  src="https://workik-widget-assets.s3.amazonaws.com/Footer1-83/v1/images/Icon-instagram.png"
                />
              </a>
            </div>
          </div>
        </div>
        <div
          class="responsive-cell-block wk-ipadp-6 wk-tab-12 wk-mobile-12 wk-desk-6 right-one"
          id="i1zj"
        >
          <form class="form-box" @submit.prevent="submitForm">
            <div class="container-block form-wrapper">
              <p class="text-blk contactus-head">Get a quote</p>
              <p class="text-blk contactus-subhead">
                We will get back to you in 24 hours
              </p>
              <div class="responsive-container-block">
                <div
                  class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12"
                  id="i10mt-7"
                >
                  <input
                    class="input"
                    v-model="firstName"
                    name="FirstName"
                    placeholder="First Name"
                    required
                  />
                </div>
                <div
                  class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12"
                  id="i1ro7"
                >
                  <input
                    class="input"
                    v-model="lastName"
                    name="Last Name"
                    placeholder="Last Name"
                    required
                  />
                </div>
                <div
                  class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-6 wk-ipadp-6 emial"
                  id="ityct"
                >
                  <input
                    class="input"
                    v-model="email"
                    name="Email"
                    placeholder="Email"
                    required
                  />
                </div>
                <div
                  class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12"
                >
                  <input
                    class="input"
                    v-model="phoneNumber"
                    name="PhoneNumber"
                    placeholder="Phone Number"
                    required
                  />
                </div>
                <div
                  class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12"
                  id="i634i-7"
                >
                  <textarea
                    class="textinput"
                    v-model="message"
                    placeholder="Type message here"
                    required
                  ></textarea>
                </div>
              </div>
              <button class="submit-btn">Get quote</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
// const apiUrl = process.env.VUE_APP_API_URL

export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      message: "",
    };
  },
  methods: {
    async submitForm() {
      try {
        await axios.post(
          process.env.VUE_APP_API_URL + 'Contact_querys/postcontactquery',
          {
            mode: "cors", // Ensure CORS mode is enabled
            headers: {
              "Content-Type": "application/json",
            },
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phoneNumber: this.phoneNumber,
            message: this.message,
          }
        );
        toast.success(
          "Your query have been sent. Our team will reach you soon..!!",
          {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } catch (error) {
        console.error(
          "Error details:",
          error.response || error.message || error
        );
        toast.error("Oops..!! Try Again", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
  },
};
</script>

<style scoped>
.landing_page * {
  font-family: Nunito, sans-serif;
}

.landing_page .responsive-container-block {
  min-height: 75px;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  justify-content: flex-start;
}

.landing_page .text-blk {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  line-height: 25px;
}

.landing_page .responsive-cell-block {
  min-height: 75px;
}

.landing_page .responsive-container-block.container {
  max-width: 1320px;
  margin-top: 60px;
  margin-right: auto;
  margin-bottom: 60px;
  margin-left: auto;
  position: relative;
}

.landing_page .form-box {
  background-color: #0a0a0a;
  color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 506px;
}

.landing_page .text-blk.contactus-head {
  font-size: 30px;
  line-height: 40px;
}

.landing_page .text-blk.contactus-subhead {
  color: #d4d4d4;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 18px;
  margin-left: 0px;
}

.landing_page .input {
  width: 100%;
  height: 50px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 18px;
  padding-top: 1px;
  padding-right: 125px;
  padding-bottom: 1px;
  padding-left: 22.5px;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-color: #767676;
  border-right-color: #767676;
  border-bottom-color: #767676;
  border-left-color: #767676;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  padding: 1px 12.5px 1px 22.5px;
}

.landing_page .textinput {
  width: 100%;
  height: 180px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  margin-left: 0px;
  font-size: 18px;
  padding-top: 22px;
  padding-right: 22px;
  padding-bottom: 22px;
  padding-left: 22px;
  background-color: #212223;
  color: white;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-color: #767676;
  border-right-color: #767676;
  border-bottom-color: #767676;
  border-left-color: #767676;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
}

.landing_page .submit-btn {
  width: 100%;
  height: 56px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #0d6efd;
  font-size: 18px;
  font-weight: 600;
  color: white;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
}

.landing_page
  .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-6.wk-ipadp-6.emial {
  padding-top: 0px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-left: 0px;
}

.landing_page
  .responsive-cell-block.wk-ipadp-6.wk-tab-12.wk-mobile-12.wk-desk-6.right-one {
  display: flex;
  justify-content: center;
}

.landing_page
  .responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-tab-12.wk-mobile-12.left-one {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
}

.landing_page .text-blk.section-subhead {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 50px;
  margin-left: 0px;
  max-width: 420px;
  font-size: 18px;
  color: #b6b6b6;
}

.landing_page .text-blk.section-head {
  font-size: 40px;
  line-height: 55px;
  font-weight: 800;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
  margin-left: 0px;
  max-width: 450px;
}

.landing_page .icons-container {
  max-width: 450px;
  display: flex;
  justify-content: space-evenly;
}

.landing_page .img {
  width: 31px;
  height: 31px;
}

.landing_page .responsive-container-block.big-container {
  background-color: black;
  padding-top: 0px;
  padding-right: 50px;
  padding-bottom: 0px;
  padding-left: 50px;
  position: relative;
  border-radius: 10px;
}

.landing_page .bg-img {
  width: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  height: 100%;
  opacity: 0.5;
  object-fit: cover;
}

@media (max-width: 768px) {
  .landing_page
    .responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-tab-12.wk-mobile-12.left-one {
    justify-content: center;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 30px;
    margin-left: 0px;
  }

  .landing_page
    .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-6.wk-ipadp-6.emial {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .landing_page
    .responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-tab-12.wk-mobile-12.left-one {
    margin: 0 0 40px 0;
  }
}

@media (max-width: 500px) {
  .landing_page .text-blk.section-head {
    font-size: 26px;
    line-height: 40px;
  }

  .landing_page .responsive-container-block.big-container {
    padding-top: 0px;
    padding-right: 20px;
    padding-bottom: 0px;
    padding-left: 20px;
  }

  .landing_page .text-blk.section-subhead {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 30px;
    margin-left: 0px;
    font-size: 16px;
  }

  .landing_page .form-box {
    padding-top: 30px;
    padding-right: 15px;
    padding-bottom: 30px;
    padding-left: 15px;
  }

  .landing_page
    .responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-tab-12.wk-mobile-12.left-one {
    margin: 0 0 30px 0;
  }

  .landing_page .input {
    height: 45px;
  }

  .landing_page .text-blk.contactus-head {
    font-size: 24px;
    line-height: 34px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&amp;display=swap");

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
}

.wk-desk-1 {
  width: 8.333333%;
}

.wk-desk-2 {
  width: 16.666667%;
}

.wk-desk-3 {
  width: 25%;
}

.wk-desk-4 {
  width: 33.333333%;
}

.wk-desk-5 {
  width: 41.666667%;
}

.wk-desk-6 {
  width: 50%;
}

.wk-desk-7 {
  width: 58.333333%;
}

.wk-desk-8 {
  width: 66.666667%;
}

.wk-desk-9 {
  width: 75%;
}

.wk-desk-10 {
  width: 83.333333%;
}

.wk-desk-11 {
  width: 91.666667%;
}

.wk-desk-12 {
  width: 100%;
}

@media (max-width: 1024px) {
  .wk-ipadp-1 {
    width: 8.333333%;
  }

  .wk-ipadp-2 {
    width: 16.666667%;
  }

  .wk-ipadp-3 {
    width: 25%;
  }

  .wk-ipadp-4 {
    width: 33.333333%;
  }

  .wk-ipadp-5 {
    width: 41.666667%;
  }

  .wk-ipadp-6 {
    width: 50%;
  }

  .wk-ipadp-7 {
    width: 58.333333%;
  }

  .wk-ipadp-8 {
    width: 66.666667%;
  }

  .wk-ipadp-9 {
    width: 75%;
  }

  .wk-ipadp-10 {
    width: 83.333333%;
  }

  .wk-ipadp-11 {
    width: 91.666667%;
  }

  .wk-ipadp-12 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .wk-tab-1 {
    width: 8.333333%;
  }

  .wk-tab-2 {
    width: 16.666667%;
  }

  .wk-tab-3 {
    width: 25%;
  }

  .wk-tab-4 {
    width: 33.333333%;
  }

  .wk-tab-5 {
    width: 41.666667%;
  }

  .wk-tab-6 {
    width: 50%;
  }

  .wk-tab-7 {
    width: 58.333333%;
  }

  .wk-tab-8 {
    width: 66.666667%;
  }

  .wk-tab-9 {
    width: 75%;
  }

  .wk-tab-10 {
    width: 83.333333%;
  }

  .wk-tab-11 {
    width: 91.666667%;
  }

  .wk-tab-12 {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .wk-mobile-1 {
    width: 8.333333%;
  }

  .wk-mobile-2 {
    width: 16.666667%;
  }

  .wk-mobile-3 {
    width: 25%;
  }

  .wk-mobile-4 {
    width: 33.333333%;
  }

  .wk-mobile-5 {
    width: 41.666667%;
  }

  .wk-mobile-6 {
    width: 50%;
  }

  .wk-mobile-7 {
    width: 58.333333%;
  }

  .wk-mobile-8 {
    width: 66.666667%;
  }

  .wk-mobile-9 {
    width: 75%;
  }

  .wk-mobile-10 {
    width: 83.333333%;
  }

  .wk-mobile-11 {
    width: 91.666667%;
  }

  .wk-mobile-12 {
    width: 100%;
  }
}
</style>
