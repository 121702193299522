<template>

    <div style="margin-top: 200px; display: grid; place-items: center" >
      <form class="honorary_form" @submit.prevent="submitHonoraryMembersData">
        <h3 class="text-center display-7">NCCP Honorary Members Form</h3><br>
        <div>
          <label for="name">Name:</label><br>
          <input type="text" v-model="HonoraryMembersData.name" id="name" required>
        </div>
        <div>
          <label for="id">ID:</label><br>
          <input type="text" v-model="HonoraryMembersData.id" id="id" required>
        </div>
        <div>
          <label for="state">State:</label><br>
          <input type="text" v-model="HonoraryMembersData.state" id="state">
        </div>
        <div>
          <label for="address">Address:</label><br>
          <input type="text" v-model="HonoraryMembersData.address" id="address" required>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  </template>
  
  
  <script>
  import axios from 'axios';

  
  export default {
    components: {
    },
    data() {
      return {
        HonoraryMembersData: {
          name: '',
          id: '',
          state: '',
          address: '',
        },
      };
    },
    methods: {
      async submitHonoraryMembersData() {
        try {
         await axios.post(process.env.VUE_APP_API_URL + '/honorarymembers/PostHonorarymembers', this.HonoraryMembersData);
          this.$emit('formSubmitted');
          alert("Successfully Submitted")
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      }
    }
  };
  
  
    </script>
  
  <style scoped>
.honorary_form{ 
  width: 25%;
  padding: 20px;
  
  }
  
  input{
    width: 100% !important;
    border: none;
    border-bottom: 1px solid gray;
    outline: none;
  }
  
  button{
    width: 100%;
    border: none;
    outline: none;
    background-color: #0d6efd;
    padding: 5px 20px;
    color: #fff;
    font-weight: 600
  }
  div {
    margin-bottom: 15px;
  }
  </style>
  