<template>

  <BannerSection title="Executive Members" />

  <div class="container">
    <div class="constituton-content">
      <div>
        <div class="content1 mt-5">
          <div class="container">

           <div class="members_btn_div">
       <button class="members_btn active" :class="{ active: activeTab === 'em2015-2016' }" @click="setActiveTab('em2015-2016')">Executive Members(2015-2016)</button>
       <button class="members_btn" :class="{ active: activeTab === 'em2014-2015' }" @click="setActiveTab('em2014-2015')">Executive Members(2014-2015)</button>
       <button class="members_btn" :class="{ active: activeTab === 'em2013-2014' }" @click="setActiveTab('em2013-2014')">Executive Members(2013-2014)</button>
           </div>

           
    <div class="member-section mt-5" v-if="fetchedData.length" v-show="activeTab === 'em2015-2016'">
      <div class="profile" v-for="items in fetchedData" :key="items._id">
        <div class="bg-img">
          <div class="img-profile">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-person-fill-check" viewBox="0 0 16 16">
              <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
              <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"/>
            </svg>
          </div>
      </div>
      <h4 class="text-center mt-5">{{items.position}}</h4>

      <div class="members-details mt-2">
  <p><i class="bi bi-person-fill"></i>  <span>{{items.name}} </span></p>
  <p><i class="bi bi-envelope-at-fill"></i>  <span>{{items.email}} </span> </p>
  <p><i class="bi bi-geo-alt-fill"></i> <span>{{items.address}} </span></p>
      </div>
      <div class="social-icon"></div>
    </div>
            
           </div>
<!-- ------------2nd sec---------------------------- -->
<div class="member-section mt-5" v-if="fetchedData.length" v-show="activeTab === 'em2014-2015'">
  <div class="profile" v-for="items in fetchedData" :key="items._id">
    <div class="bg-img">
      <div class="img-profile">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-person-fill-check" viewBox="0 0 16 16">
          <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
          <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"/>
        </svg>
      </div>
  </div>
  <h4 class="text-center mt-5">{{items.position}}</h4>

  <div class="members-details mt-2">
<p><i class="bi bi-person-fill"></i>  <span>{{items.name}} </span></p>
<p><i class="bi bi-envelope-at-fill"></i>  <span>{{items.email}} </span> </p>
<p><i class="bi bi-geo-alt-fill"></i> <span>{{items.address}} </span></p>
  </div>
  <div class="social-icon"></div>
</div>
        
       </div>

       <!-- ------------------3rd sec---------------------------- -->
       <div class="member-section mt-5" v-if="fetchedData.length" v-show="activeTab === 'em2013-2014'">
        <div class="profile" v-for="items in fetchedData" :key="items._id">
          <div class="bg-img">
            <div class="img-profile">
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-person-fill-check" viewBox="0 0 16 16">
                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"/>
              </svg>
            </div>
        </div>
        <h4 class="text-center mt-5">{{items.position}}</h4>
  
        <div class="members-details mt-2">
    <p><i class="bi bi-person-fill"></i>  <span>{{items.name}} </span></p>
    <p><i class="bi bi-envelope-at-fill"></i>  <span>{{items.email}} </span> </p>
    <p><i class="bi bi-geo-alt-fill"></i> <span>{{items.address}} </span></p>
        </div>
        <div class="social-icon"></div>
      </div>
              
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- ----swiper--------- -->
</template>

<script>
import axios from "axios"
import BannerSection from "@/components/BannerSection.vue";

export default {
  components: {
    BannerSection,
  },

  data() {
    return {
      activeTab: 'em2015-2016',
    fetchedData: []
    };
  },

  computed: {
    isActive() {
      return (path) => {
        return this.$route.path === path;
      };
    },
  },

  methods:{

    async fetchmemberdata () {
      try{
        const response = await axios.get(process.env.VUE_APP_API_URL + '/members/getmemberData');
        this.fetchedData = response.data;
        console.log(this.fetchedData)
      }

      catch(error) {
        console.error('Error Fertching From Data', error);
      }
    },

    setActiveTab(tab){
      this.activeTab = tab;
    }
  },

 mounted() {
    this.fetchmemberdata();
  }
};
</script>


<style scoped>
.members_btn_div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.members_btn_div .members_btn{
  padding:10px;
  border: none;
  outline: none;
  font-size: 14px;
  border-radius: 5px;
}
.members_btn_div .members_btn .active{
  padding:10px;
  border: none;
  outline: none;
  font-size: 14px;
  border-radius: 5px;
  background: #0d6efd;
  color: #fff;
}

.member-section{
  display: grid;
  grid-template-columns: repeat(4 , 1fr);
  gap: 20px;
}
.profile{
  width: 100%;
  box-shadow: -5px -5px 9px rgba(255,255,255,0.45), 5px 5px 9px rgba(94,104,121,0.3);
  border-radius: 10px;
  text-align: center;
}
.bg-img{
  height: 75px;
  width: 100%;
  background: linear-gradient(to right, #0d6efd, #fff);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: grid;
  place-content: center;
}

.img-profile{
  height: 75px;
  width: 75px;
  border-radius: 50%;
  background: #fff;
  display: grid;
  place-content: center;
  box-shadow: 
  -10px -10px 15px rgba(255, 255, 255, 0.1),
  10px 10px 15px rgba(70, 70, 70, 0.12),
  inset -10px -10px 15px rgba(255, 255, 255, 0.1),
  inset 10px 10px 15px rgba(70, 70, 70, 0.12);
  position: relative;
  top: 30px;
}

.profile h4{
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
}
.members-details{
  text-align: left !important;
  padding: 5px 20px;
}
.members-details i{
  color:#0d6efd
}
.members-details span{
  margin-left: 10px
}
.social-icon{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  position: relative;
  bottom: 0px;

}
.bi-instagram{
  color: red;
  cursor: pointer;
}
.bi-twitter-x{
  color: gray;
  cursor: pointer;
}
.bi-facebook{
  color: blue;
  cursor: pointer;
}
.bi-linkedin{
  color:blue;
  cursor: pointer;
}
</style>
