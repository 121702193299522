<template>
  <header id="header" class="fixed-top d-flex align-items-center">
    <div class="container">
      <div class="header-container d-flex align-items-center justify-content-between">
        <div class="logo">
          <router-link to="/">
            <img src="../assets/logo.png" alt="" class="img-fluid">
          </router-link>
        </div>

        <nav id="navbar" class="navbar">
          <ul>
            <li>
              <router-link :to="`/`" class="nav-link scrollto" :class="{ 'active': isActive('/') }">Home</router-link>
            </li>
            <li class="dropdown">
              <a href="#" :class="{ 'active': isActive('/Guidelines') }">
                <span>About Us</span> <i class="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li><router-link to="/Guidelines" :class="{ 'active': isActive('/Guidelines') }">Our Guidelines</router-link></li>
                <li><router-link to="/Constitution" :class="{ 'active': isActive('/Constitution') }">Our Constitution </router-link></li>
                <li class="dropdown">
                  <a href="#">
                    <span>Executive Members</span>
                    <i class="bi bi-chevron-right"></i>
                  </a>
                  <ul>
                    <li><router-link to="/ExecutiveMembers">Executive Members(2015-2016)</router-link> </li>
                    <li><router-link to="/ExecutiveMembers">Executive Members(2014-2015)</router-link></li>
                    <li><a href="#">Executive Members(2013-2014)</a></li>
                  </ul>
                </li>
                <li><router-link to="/Rules&Regulations" :class="{ 'active': isActive('/Rules&Regulations') }">Rules & Regulations</router-link></li>
                <li><router-link to="/about" :class="{ 'active': isActive('/about') }">Who We Are</router-link></li>
                <li><router-link to="/President_And_Secretaries" :class="{ 'active': isActive('/President_And_Secretaries') }">President & Secretaries</router-link></li>
                <li class="dropdown">
                  <a href="#">
                    <span>NCCP Members</span>
                    <i class="bi bi-chevron-right"></i>
                  </a>
                  <ul>
                    <li><router-link to="/Nccp_Honorary_Members" :class="{ 'active': isActive('/Nccp_Honorary_Members') }">NCCP Honorary Members</router-link></li>
                    <li><router-link to="/Nccp_Life_Members" :class="{ 'active': isActive('/Nccp_Life_Members') }">NCCP Life Members</router-link></li>
                    
                    <li><router-link to="/Nccp_Ordinary_Members" :class="{ 'active': isActive('/Nccp_Ordinary_Members') }">NCCP Oridinary Fellow Members</router-link></li>
                    <li><router-link to="/Nccp_Life_Fellow_Members" :class="{ 'active': isActive('/Nccp_Life_Fellow_Members') }">NCCP Life Fellow Members</router-link></li>
                  </ul>
                </li>
              </ul>
            </li>

            <li class="dropdown">
              <a href="#" :class="{ 'active': isActive('/activities') }">
                <span>Activities</span> <i class="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li><router-link to="/nccp_education" :class="{ 'active': isActive('/Education') }">Education</router-link></li>
                <li><router-link to="/Napcon_Nccp" :class="{ 'active': isActive('/Napcon_Nccp') }">NAPCON</router-link></li>
                <li><router-link to="/conference" :class="{ 'active': isActive('/conference') }">Conferance</router-link></li>
              </ul>
            </li>

            <li class="dropdown">
              <a href="#" :class="{ 'active': isActive('/awards') }">
                <span>Awards</span> <i class="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li v-for="(menu, index) in submenu_Items" :key="index">
                <a  @click="toggleFuction(index)" style="cursor: pointer">{{ menu.submenuItems }}</a>
                <!-- <router-link :to="/awards/+index">{{ menu.submenuItems }}</router-link> -->
                </li>
              </ul>
            </li>
            <li>
              <router-link :to="`/nccp_publications`" class="nav-link scrollto" :class="{ 'active': isActive('/nccp_publications') }">NCCP Publications</router-link>
            </li>
            <li>
              <router-link :to="`/our_gallery`" class="nav-link scrollto" :class="{ 'active': isActive('/our_gallery') }">Gallery</router-link>
            </li>

            <li class="dropdown">
              <a href="#" :class="{ 'active': isActive('/events') }">
                <span>Our Events</span> <i class="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li><router-link to="/NAPCON2020">NAPCON 2020</router-link></li>
                <li><router-link to="/NAPCON2021">NAPCON 2021</router-link></li>
              </ul>
            </li>
            <a class="getstarted" @click="popupfunction()"> Contact Us</a>
          </ul>
         
          <i class="bi bi-list mobile-nav-toggle" @click="toggleMobileMenu"></i>
        </nav>
      </div>  
      
      <div class="pop-up-contact" v-show="showpopup" data-aos="zoom-in-up" data-aos-delay="50" data-aos-duration="1000">
        <div class="popup-form">
          <i class="bi bi-x-circle-fill" @click="popupfunction()"></i>
          <form @submit.prevent="contactForm" class="formsubmit">
           
           <div class="form-input">
          
<input type="text" placeholder="Full Name" required>
<input type="email" placeholder="Email" required>
           </div><br><br>

           <div class="form-input">
            <input type="text" placeholder="Contact No." required>
            <input type="text" placeholder="Subject" required>
                       </div><br><br>

                       <div>
                     <input type="text" placeholder="Send Message..." class="input-message">
                       </div><br><br>

                       <div class="submit">
                        <input type="submit"  value="Send">
                       </div>
          </form>
        </div>
          </div>
      <!-- End Header Container -->
    </div>
  </header>





</template>


<script>



export default {
  data() {
    return {
      showpopup: false,

      submenu_Items:[
      {submenuItems: 'Parke Davis/NCCP(I) Chest Oration'},
      {submenuItems: 'Raman Viswanathan Memorial Lecture'},
      {submenuItems: 'NCCP(I) Cipla Chest Oration'},
      {submenuItems: 'NCCP(I) German Remedies Chest Oration'},
      {submenuItems: 'NCCP(I) Lupin Chest Oration'},
      {submenuItems: 'Young Scientist Award of NCCP(I)'},
    ],
      award_Menu_Data: [
        {
          heading: 'Parke Davis/NCCP(I) Chest Oration',
          heading2: '(A) WARNER NCCP (I) ORATION (renamed as PARKE-DAVIS NCCP (I) CHEST ORATION in 1992)',
          paragraph: 'The Association / College has awards to encourage research activities in the field of T.B. and Respiratory Diseases as well as their contribution to the specialty.',
          subparagraph: 'This was instituted in 1972 as “Warner Chest Oration” with the purpose promoting scientific research in the field of Chest Diseases, and in particular in field of allergy.',
          content: 'The Warner Hindustan Ltd. through the Asthma, Bronchitis & Cancer Lung Foundation of India, funded annually an oration on any aspect of Chest Diseases, to be delivered at the time of the annual conference.<br>On the special request of the donors, the 151 oration was delivered in 1972 by Dr. Vishwanathan, who had perhaps the longest experience in the field of chest diseases. The name of the oration was changed to Parke-Davis / NCCP (I) Chest Oration since 1992, on the request of the organization since Warner Hindustan Company merged in Parke-Davis Company. The award was finally discontinued after 1995. The list of orators is given below.'
        },
        {
          heading: 'Raman Viswanathan Memorial Lecture',
          subparagraph: 'Raman Viswanathan Memorial Lecture was started in 1989, to honour and perpetuate the memory of Late Col. Raman Viswanathan, founder Director, V.P. Chest Institute, a great scientist...'
        },
        {
          heading: 'NCCP(I) Cipla Chest Oration',
          subparagraph: 'NCCP (I) Cipla Chest Oration was created with the objective of arranging oration every year by an eminent scientist working in the field of Pulmonology, preferably on Bronchial Asthma...'
        },
        {
          heading: 'NCCP(I) German Remedies Chest Oration',
          subparagraph: 'This awards was created in 2002 by the financial support of MIS German Remedies. The awards was given every year to an eminent scientist working in the field of Pulmonology. Following...'
        },
        {
          heading: 'NCCP(I) Lupin Chest Oration',
          subparagraph: 'The award was created in 2005 by financial support of M/s Lupin India Ltd. The award is given every year to eminent Pulmonologist. Following persons have received the award:'
        },
        {
          heading: 'Young Scientist Award of NCCP(I)',
          subparagraph: 'To encourage research in young scientists, a cash prize of Rs. 1000/- will be given to the best paper presented by him in the annual Conference of the College. He should be the first...'
        },
      ],
    };
  },
  methods: {
    popupfunction() {
      this.showpopup = !this.showpopup;
    },
    async toggleFuction(id) {
      await this.$router.push({ name: "Award_section", params: { id: id } });
      this.$router.go(0)
      // this.$router.push(`/awards/${id}`);
    },

    toggleMobileMenu() {
      const navbar = document.querySelector('#navbar');
      navbar.classList.toggle('navbar-mobile');
    },
  },

  computed: {
    isActive() {
      return (path) => {
        return this.$route.path === path;
      }
    }
  }
};


</script>

<style scoped>
.navbar .active {
  color: #0d6efd; /* Define your active link styles */
}
a {
    color: #0d6efd;;
    text-decoration: none;
  }

.icon-fix{
  position: fixed;
  top: 45%;
  left: 0px;

}
.round{
  margin: 0px 0px;
  padding: 10px;
  border-radius: 50%
}

.round i{

}
.bi-facebook{
  color: #0d6efd
}

.bi-instagram{
  color: red
}

.bi-twitter-x{
  color: gray;
}

.bi-linkedin{
  color: rgb(11, 45, 196)
}

  
  
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #0d6efd;;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #00c28e;
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Disable AOS delay on mobile
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    top: 20px;
  }
  
  #header .header-container {
    background: #fff;
    border-radius: 5px;
    height: 60px;
    margin-top: -15px;
  }
  
  
  #header .logo {
  
    border-radius: 10px;
    height: 60px;
    width: 60px;
    padding: 3px;
  }
  
  #header .logo h1 {
    font-size: 26px;
    padding: 0;
    line-height: 1;
    font-weight: 700;
  }
  
  #header .logo h1 a,
  #header .logo h1 a:hover {
    color: #fff;
    text-decoration: none;
  }
  
  #header .logo img {
height: 100%;
width: 100%;
object-fit: cover;
margin-left: 40px
  }
  
  @media (max-width: 992px) {
    #header {
    
      top: 20px;

    }
  
    #header.header-scrolled,
    #header .header-container {
     height: 50px;
     width: 94%;
    }
  
    #header .logo {
      height: 45px;
      width: 45px;
    }
  
    #header .logo h1 {
      font-size: 24px;
    }
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }
  
  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    color: #36343a;
    transition: 0.3s;
    font-size: 13px;
    font-weight: 600;
 
    white-space: nowrap;
    transition: 0.3s;
  }
  
  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  
  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: #0d6efd;;
  }
  
  .navbar .getstarted,
  .navbar .getstarted:focus {
    background: #0d6efd;;
    padding: 10px 25px;
    margin-left: 30px;
    margin-right: 15px;
    border-radius: 50px;
    color: #fff;
  }
  
  .navbar .getstarted:hover,
  .navbar .getstarted:focus:hover {
    color: #fff;
    cursor: pointer
  }
  
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }
  
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar .dropdown ul a {
    padding: 10px 20px;
    text-transform: none;
    font-weight: 500;
  }
  
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: #0d6efd;;
  }
  
  .navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  
  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
  
    .navbar .dropdown .dropdown:hover>ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: black;
    font-size: 28px;
    cursor: pointer;
    display: none;
    
    transition: 0.5s;
    padding-left: 10px
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
  
    .navbar ul {
      display: none;
    }
  }
  
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(29, 28, 31, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  
  .navbar-mobile a,
  .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #36343a;
  }
  
  .navbar-mobile a:hover,
  .navbar-mobile .active,
  .navbar-mobile li:hover>a {
    color: #0d6efd;;
  }
  
  .navbar-mobile .getstarted,
  .navbar-mobile .getstarted:focus {
    margin: 15px;
  }
  
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar-mobile .dropdown ul a:hover,
  .navbar-mobile .dropdown ul .active:hover,
  .navbar-mobile .dropdown ul li:hover>a {
    color: #0d6efd;;
  }
  
  .navbar-mobile .dropdown>.dropdown-active {
    display: block;
  }

  .pop-up-contact{
    display: grid;
    place-content: center;
    place-items: center;
    width: 100%;
   height: 100vh;
    
    background: rgba(0, 0, 0, 0.8);

  }
.popup-form{
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  padding: 50px;
}

.popup-form i{
  cursor: pointer;
  position: absolute;
  right: 26%;
  margin-top: -30px;
  font-size: 20px;
  color: #0d6efd;
}
  .formsubmit{
padding: 30px;
  }

  .form-input{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .form-input input{
width: 300px;
border: none;
border-bottom: 1px solid #0d6efd;
background: transparent;
  }

  ::placeholder{
    color: #fff;
  }

  .input-message{
    width: 100%;
    background: transparent;
    border: none;
    border-bottom:1px solid #0d6efd;
  }
  .submit{
    text-align: center;
  }
  .submit input{
    padding: 3px 10px;
    width: 50%;
    background: transparent;
    color: #fff;
    border: 3px solid #0d6efd;
  }

  .submit input:hover{
    padding: 3px 10px;
    width: 50%;
    background: #0d6efd;
    color: #fff;
    border: 3px solid #0d6efd;
    transition: 0.5s;
  }

  @media(max-width:768px){
      .form-input{
    display: grid;
    grid-template-columns: 100%;
    gap: 20px;
  }
  .popup-form i[data-v-12c3dba6] {
    cursor: pointer;
    position: absolute;
    right: 12%;
  
    margin-top: 25px;
    font-size: 20px;
    color: #0d6efd;
  }
  }
</style>
