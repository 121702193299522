<template>
  <BannerSection title="NCCP Honorary Members" />
  <div class="container"> 
    <h3>NCCP Honorary Members</h3><hr> <div class="member-section mt-5" v-if="fetchedhonoraryData.length">
    <div class="profile" v-for="items in fetchedhonoraryData" :key="items._id">
      <div class="bg-img">
        <div class="img-profile">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-person-fill-check" viewBox="0 0 16 16">
            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
            <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"/>
          </svg>
        </div>
      </div>
      <h4 class="text-center mt-5">{{items.name}}</h4>

      <div class="members-details mt-2">
        <p><i class="bi bi-person-fill"></i>  <span>{{items.id}} </span></p>
        <p><i class="bi bi-envelope-at-fill"></i>  <span>{{items.state}} </span> </p>
        <p><i class="bi bi-geo-alt-fill"></i> <span>{{items.address}} </span></p>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import axios from 'axios';
import BannerSection from '@/components/BannerSection.vue'



export default {
  components: {
    BannerSection,
  },
  data() {
    return {
      fetchedhonoraryData: [],
    }
  },
  methods: {
    async fetchHonorarymemberdatas() {
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + '/honorarymembers/getHonorarymembers');
        this.fetchedhonoraryData = response.data;
        console.log(this.fetchedhonoraryData, "this is fetched data");
      } catch (error) {
        console.error('Error Fetching Data', error);
      }
    }
  },
  mounted() {
    this.fetchHonorarymemberdatas();
  }
}
</script>

<style>
.member-section{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.profile{
  width: 100%;
  box-shadow: -5px -5px 9px rgba(255,255,255,0.45), 5px 5px 9px rgba(94,104,121,0.3);
  border-radius: 10px;
  text-align: center;
}
.bg-img{
  height: 75px;
  width: 100%;
  background: linear-gradient(to right, #0d6efd, #fff);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: grid;
  place-content: center;
}

.img-profile{
  height: 75px;
  width: 75px;
  border-radius: 50%;
  background: #fff;
  display: grid;
  place-content: center;
  box-shadow: 
  -10px -10px 15px rgba(255, 255, 255, 0.1),
  10px 10px 15px rgba(70, 70, 70, 0.12),
  inset -10px -10px 15px rgba(255, 255, 255, 0.1),
  inset 10px 10px 15px rgba(70, 70, 70, 0.12);
  position: relative;
  top: 30px;
}

.profile h4{
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
}
.members-details{
  text-align: left !important;
  padding: 5px 20px;
}
.members-details i{
  color:#0d6efd
}
.members-details span{
  margin-left: 10px
}
.social-icon{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  position: relative;
  bottom: 0px;

}
.bi-instagram{
  color: red;
  cursor: pointer;
}
.bi-twitter-x{
  color: gray;
  cursor: pointer;
}
.bi-facebook{
  color: blue;
  cursor: pointer;
}
.bi-linkedin{
  color:blue;
  cursor: pointer;
}

@media(max-width:768px){
  .member-section{
    display: grid;
    grid-template-columns: 100%;
    gap: 20px;
  }
}
</style>
