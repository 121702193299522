<template>
  <div class="wrapper">
    <div class="logo">
      <img src="../assets/logo.png" alt="Logo" height="80px" width="80px" />
    </div>
    <div class="text-center mt-4 name">
      NCCP
    </div>
    <form class="p-3 mt-3" @submit.prevent="login">
      <div class="form-field d-flex align-items-center">
        <span class="far fa-user"></span>
        <input type="text" v-model="username" placeholder="Username" required />
      </div>
      <div class="form-field d-flex align-items-center">
        <span class="fas fa-key"></span>
        <input type="password" v-model="password" placeholder="Password" required />
      </div>
      <button type="submit" class="btn mt-3">Login</button>
    </form>
    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';



export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/api/auth/login', {
          username: this.username,
          password: this.password,
        });

        if (response.data.token) {
          const expirationTime = new Date().getTime() + 60 * 1000; // 1 minute from now

          // Store token and expiration time in localStorage
          localStorage.setItem('token', `Bearer ${response.data.token}`);
          localStorage.setItem('tokenExpiration', expirationTime);

          // Use nextTick to ensure Vue updates before navigation
          this.$nextTick(() => {
            // Redirect to Admin Dashboard
            this.$router.push('/Admin_Dashboard');
          });

          // Show success notification
          toast.success('You have logged in successfully', {
            position: 'bottom-right',
            autoClose: 3000,
          });
        }
      } catch (error) {
        this.errorMessage = error.response?.data?.message || 'An unexpected error occurred.';
        toast.error(this.errorMessage, {
          position: 'bottom-right',
          autoClose: 3000,
        });
      }
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background: #ecf0f3;
}

.wrapper {
  max-width: 350px;
  min-height: 500px;
  margin: 80px auto;
  padding: 40px 30px;
  background-color: #ecf0f3;
  border-radius: 15px;
  box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #fff;
}

.logo {
  width: 80px;
  margin: auto;
}

.logo img {
  width: 100%;
  height: 80px;
  border-radius: 50%;
}

.name {
  font-weight: 600;
  font-size: 1.4rem;
  color: #555;
}

.form-field {
  padding-left: 10px;
  margin-bottom: 20px;
  border-radius: 20px;
}

.form-field input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 1.2rem;
  padding: 10px 15px;
}

.btn {
  width: 100%;
  height: 40px;
  background-color: #03a9f4;
  color: #fff;
  border-radius: 25px;
}
</style>
