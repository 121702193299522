<template>
    <BannerSection title="Nccp Ordinary Fellow Members" />
  <div class="container">
<h3>NCCP Ordinary Fellow Members</h3><hr>
<div class="profile" v-for="items in fetchedhonoraryData" :key="items._id">
    <div class="bg-img">
      <div class="img-profile">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-person-fill-check" viewBox="0 0 16 16">
          <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
          <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"/>
        </svg>
      </div>
    </div>
    <h4 class="text-center mt-5">{{items.name}}</h4>

    <div class="members-details mt-2">
      <p><i class="bi bi-person-fill"></i>  <span>{{items.id}} </span></p>
      <p><i class="bi bi-envelope-at-fill"></i>  <span>{{items.state}} </span> </p>
      <p><i class="bi bi-geo-alt-fill"></i> <span>{{items.address}} </span></p>
    </div>
  </div>

  </div>
</template>

<script>
import BannerSection from '@/components/BannerSection.vue'

export default {
components: {
BannerSection,
},
data(){
    return{
        fetchedhonoraryData: [
{
    name: 'Dr. OM PRAKASH PINDIDAS KAPOOR',
    id: 'OF-0006',
    address: '278, D.N. ROAD,  ABOVE KHADI BHAWAN, 3rd FLOOR, FORT, MUMBAI-400001',
    state: 'MAHARASHTRA',
}
]
    }
}
}
</script>

<style scoped>

.profile{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

</style>