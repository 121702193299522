<template>

    <div style="margin-top: 200px; display: grid; place-items: center" >
      <form @submit.prevent="executiveMembers">
        <h3 class="text-center display-7">Executive Member List</h3><br>
        <div>
          <label for="name">Name:</label><br>
          <input type="text" v-model="memberData.name" id="name" required>
        </div>
        <div>
          <label for="email">Email</label><br>
          <input type="email" v-model="memberData.email" id="email" required>
        </div>
        <div>
          <label for="position">Position</label><br>
          <input type="postion" v-model="memberData.position" id="position">
        </div>
        <div>
          <label for="address">Address</label><br>
          <input type="text" v-model="memberData.address" id="address" required>
        </div>
        <button type="submit">Submit</button>
      </form>
      <!-- <h1>{{memberData}}</h1> -->
    </div>
  </template>
  
  
  <script>
  import axios from 'axios';
 
  
  export default {
    components: {
    },
    data() {
      return {
        memberData: {
          name: '',
          email: '',
          position: '',
          address: '',
        },
      };
    },
    methods: {
      async executiveMembers() {
        try {
         await axios.post(process.env.VUE_APP_API_URL + '/members/PostmemberForm', this.memberData);
          this.$emit('formSubmitted');
          alert("Successfully Submitted")
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      }
    }
  };
  
  
    </script>
  
  <style scoped>
  form { 
  width: 25%;
  padding: 20px;
  
  }
  
  input{
    width: 100% !important;
    border: none;
    border-bottom: 1px solid gray;
    outline: none;
  }
  
  button{
    width: 100%;
    border: none;
    outline: none;
    background-color: #0d6efd;
    padding: 5px 20px;
    color: #fff;
    font-weight: 600
  }
  div {
    margin-bottom: 15px;
  }
  </style>
  