<template>
  <div>
    <BannerSection title="NCCP Life Members" />
    <div class="container" v-if="paginatedLifeMembers.length > 0">
      <h3>NCCP Life Members<br><span class="sub-heading">National Collage Of Chest Physican---</span></h3><hr>
      <input type="text" v-model="searchQuery" placeholder="Search by name or id..." class="search-input"/>
      <div class="pagination text-right mt-2">
        <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>
      <div class="profile-cards mt-5">
        <template  v-for="(row, index) in paginatedLifeMembers" :key="index">
          <div class="profile" >
          <div class="bg-img">
            <div class="img-profile">
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-person-fill-check" viewBox="0 0 16 16">
                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"/>
              </svg>
            </div>
          </div>
          <h4 class="text-center mt-5">{{row.name}}</h4>
          <div class="members-details mt-2">
            <p><i class="bi bi-person-fill"></i>  <span>{{row.id}} </span></p>
            <p v-if="row.email"><i class="bi bi-envelope-at-fill"></i>  <span>{{row.email}} </span> </p>
            <p class="address"><i class="bi bi-geo-alt-fill"></i> <span>{{row.address}} </span></p>
            <p class="number" v-if="row.phone"><i class="bi bi-telephone-inbound-fill"></i><span>{{row.phone}} </span></p>
            <p class="state" v-if="row.state"><i class="bi bi-crosshair"></i><span>{{row.state}} </span></p>
          </div>
        </div>
        </template>
      </div>
      <div class="pagination text-right">
        <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import BannerSection from '@/components/BannerSection.vue';


export default {
  components: { BannerSection },
  data() {
    return {
      lifeMembers: [],
      currentPage: 1,
      itemsPerPage: 12,
      searchQuery: ''
    };
  },
  computed: {
    filteredLifeMembers() {
    if (this.searchQuery) {
      return this.lifeMembers.filter(member => 
        member.name.toLowerCase().includes(this.searchQuery.toLowerCase()) || 
        member.id.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
    return this.lifeMembers;
  },
    
    paginatedLifeMembers() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredLifeMembers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredLifeMembers.length / this.itemsPerPage);
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + '/fellowmember/getfellowmember');
        this.lifeMembers = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.scrollToTop();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.scrollToTop();
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 300, behavior: 'smooth' });
    }
  }
};
</script>

<style scoped>
.profile-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.profile:hover {
  transform: scale(1.1);
  transition: .6s;
  cursor: pointer;
}

.members-details p {
  font-size: 13px;
}

.address {
  font-size: 13px;
}

.sub-heading {
  font-size: 13px;
}

.pagination {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 30px;
}

.pagination button {
  margin: 0 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

@media(max-width:768px){
  .profile-cards{
    display: grid;
    grid-template-columns: 100%;
    gap: 20px;
  }
}
</style>
