<template>
    <BannerSection title="NAPCON 2020" />
    <div class="container">
        <h3 class="text-center">PREPARING TOGETHER FOR A BETTER FUTURE</h3>
        <h4 class="text-center">CONFERENCE REPORT OF VIRTUAL NAPCON 2020</h4><hr>
        <h5>Dear Colleagues,</h5>
<div class="napcon">

        <div class="content mt-3">
            <p>22nd NAPCON 2020, the 22nd Joint National Conference of Pulmonary Diseases of the National College of Chest Physicians (India) and Indian Chest Society was organized for the very first time as a virtual conference due to the prevailing situation caused by COVID-19. The virtual conclave was planned as a five-day event from 27th to 31st January 2021 under the leadership of Dr. S. N. Gaur as Organising Chairman and Dr. Nikhil Sarangdhar as Organising Secretary. Dr. S. K. Katiyar was nominated as the Chairman, Scientific Committee and entrusted with the task of drafting a state-of-the-art scientific programme for the virtual conclave.</p>

            <p>Though time was short, having less than 5 months to go, the Organising Committee with the support and goodwill of the national leadership of NCCP(I) and ICS began preparations in right earnest. The theme "Preparing Together for a Better Future" was selected for virtual NAPCON 2020, with the objective to design a spectacular scientific and workshop programme, rich in academic content, exceptional in diversity and expertise of faculty and designed to change the perspective of day-to-day-clinical practice in the field of Pulmonary Medicine in this era of COVID-19. The first announcement brochure was released on 8th October and the website with online registration became operational on 10th October 2020. NAPCON 2020 website became highly popular within a short span of time, recording more than 10000 visitors within two months and subsequently social media presence with pages on Facebook and Linked In also became operational. A sponsor brochure for industry participation was released on 29th October and a final brochure with complete scientific and workshop programmes was released on 28th December 2020.</p>

            <p>Though organised differently, for the very first time as an entirely virtual conference, the response to NAPCON 2020 from the pulmonary fraternity and post-graduates was unexpected and overwhelming. A total of 4321 delegates registered for the conference. 1002 abstracts were submitted for presentation at NAPCON 2020, out of which 9 were selected for Oral presentation in the NCCP(I) - Prof. Dr. S. N. Gaur Young Scientist Award, 8 for ICS - Dr. J. C. Kothari Young Scientist Award and 350 for twelve different categories of NAPCON 2020 Award (Oral Paper) sessions, and the remaining 635 were selected for display as E-Posters in twelve different categories for NAPCON 2020 Award (E-Poster) in the Virtual E-Poster Gallery. Competitions like the NCCP(I) – NAPCON Post-Graduate Quiz and Talent Hunt were organised under the banner of NAPCON 2020 for delegates and post-graduates to encourage participation in academic as well as extra-curricular activities.</p>

            <p>A unique 3-D virtual platform was designed under supervision of Organising Committee for virtual NAPCON 2020 after four months of preparation and demonstration which went online just prior to conference commencement. The platform had several new features such as a virtual convention centre entry and walkthrough, separate lobby for conference and workshop, 7 halls for the scientific programme and free papers, E-poster gallery with separate displays for posters categorized into twelve different categories of respiratory diseases, Virtual Delegate E-kit, entertainment zone, networking lounge, and exhibition. The attractive design, layout and novel features of the virtual platform were highly appreciated by all national and overseas delegates, faculty and post-graduates alike. The Virtual Delegate E-kit incorporated the NAPCON E-Souvenir as a digital flipbook with sound, NCCP(I) text book on Clinical Pearls in Respiratory Medicine for the post-graduates, membership benefits and enrolment forms of both NCCP(I) and ICS, NCCP(I) Newsletter Lung Bulletin based on Pulmonary function tests, ICS Newsletter Respire and other publications of academic value. An entertainment zone with more than 370 online games provided opportunity for relaxation and refreshment from time to time. A delegate-cum-snack kit was dispatched personally by courier on a first-come-first-served basis to the address of registered delegates .</p>

            <p>A total of nine pre-conference virtual workshops under the leadership of workshop Directors on different topics like Allergy & Immunotherapy (by Dr. Raj Kumar), Thoracic Imaging (by Dr. Bhavin Jankharia), Pulmonary function tests (by Dr. Mohan Kumar Thekkinkattil), Respiratory failure & mechanical ventilation (by Dr. Dhruva Chaudhry), Bronchoscopy (by Dr. Rajesh Chawla), Thoracoscopy (by Dr. Rakesh Chawla), Advanced Sleep medicine (by Dr. Vikram Sarbhai), Interstitial lung diseases and lung transplantation (by Dr. Deepak Talwar & Dr. Apar Jindal) and a new workshop on Objective structured clinical examination (by Dr. Mansi Gupta & Dr. Pranav Ish) were systematically planned by the Organising committee, with support from the Scientific and Workshop committees. Dr. Nasser Yusuf in his capacity as Convenor, Workshop committee ensured smooth coordination between workshop directors and faculty for glitch-free conduction of the entire workshop programme. </p>

            <p>NAPCON 2020 began with an auspicious start on Wednesday, 27th January 2021 with the nine pre-conference workshops well attended by a total of 860 delegates. The conclave was virtually inaugurated on Wednesday, 27th January 2021 at the hands of our Chief Guest Hon'ble Dr. Harsh Vardhan Ji , Union Minister for Health and Family Welfare, Science and Technology and Earth Sciences, Government of India and our Guest of Honor Hon'ble Dr. Jitendra Singh Ji, Minister of State for North-East region, Prime Minister's Office, Ministry of Personnel, Public Grievances and Pensions , Department of Atomic Energy & Space, Government of India, who also released the E-souvenir and other conference publications in the presence of Dr. S. N. Gaur, Organising Chairman, NAPCON 2020 and Secretary, NCCP(I), Dr. Nikhil Sarangdhar, Organising Secretary, NAPCON 2020, Dr. P. D. Motiani, President, NCCP(I), Dr. D. J. Christopher, President, ICS, Dr. Rajesh Swarnakar, Secretary, ICS and Dr. S. K. Katiyar, Chairman, Scientific Committee, NAPCON 2020. The lifetime achievement awardees Dr. Rajendra Prasad of NCCP(I) and Dr. V. K. Arora, Dr. Mohammed Sabir and Dr. Surender Kashyap of ICS and the oration awardees Dr. Surender Kashyap, Dr. Manoj Goel, Dr. Nikhil Sarangdhar, Dr. Hari Mohan Kansal of NCCP(I) and Dr. Sudhir Chaudhri, Dr. Raj Kumar, Dr. Balamugesh Thanmugam and Dr. Salil Bhargava of ICS were also felicitated during the inaugural ceremony which was virtually attended by 642 delegates. </p>

<div class="hide-content" v-show="showcontent">
    <p>NAPCON 2020 began with an auspicious start on Wednesday, 27th January 2021 with the nine pre-conference workshops well attended by a total of 860 delegates. The conclave was virtually inaugurated on Wednesday, 27th January 2021 at the hands of our Chief Guest Hon'ble Dr. Harsh Vardhan Ji , Union Minister for Health and Family Welfare, Science and Technology and Earth Sciences, Government of India and our Guest of Honor Hon'ble Dr. Jitendra Singh Ji, Minister of State for North-East region, Prime Minister's Office, Ministry of Personnel, Public Grievances and Pensions , Department of Atomic Energy & Space, Government of India, who also released the E-souvenir and other conference publications in the presence of Dr. S. N. Gaur, Organising Chairman, NAPCON 2020 and Secretary, NCCP(I), Dr. Nikhil Sarangdhar, Organising Secretary, NAPCON 2020, Dr. P. D. Motiani, President, NCCP(I), Dr. D. J. Christopher, President, ICS, Dr. Rajesh Swarnakar, Secretary, ICS and Dr. S. K. Katiyar, Chairman, Scientific Committee, NAPCON 2020. The lifetime achievement awardees Dr. Rajendra Prasad of NCCP(I) and Dr. V. K. Arora, Dr. Mohammed Sabir and Dr. Surender Kashyap of ICS and the oration awardees Dr. Surender Kashyap, Dr. Manoj Goel, Dr. Nikhil Sarangdhar, Dr. Hari Mohan Kansal of NCCP(I) and Dr. Sudhir Chaudhri, Dr. Raj Kumar, Dr. Balamugesh Thanmugam and Dr. Salil Bhargava of ICS were also felicitated during the inaugural ceremony which was virtually attended by 642 delegates. </p>



    <p>An extensive state-of-the-art scientific programme for NAPCON 2020 was drafted under the dynamic leadership of Dr. S. K. Katiyar, Chairman, Scientific Committee, with inputs from other members of the scientific committee and included participation of 460 national and 119 international faculty. For the very first time, 19 international Chest associations were invited to design symposia on specific topics of interest and depute their senior faculty members to participate in the scientific deliberations. The Asian-Pacific Society of Respirology designed a symposium on COVID-19 in the Asia-Pacific and deputed faculty members from 9 societies including the Japan Respiratory Society, Thoracic Society of Australia and New Zealand, Taiwan Society of Pulmonary & Critical Care Medicine, Indonesian Society of Respirology, Malaysian Thoracic Society , Singapore Thoracic Society, Hong Kong Thoracic Society, Mongolian Respiratory Society and the Sri Lanka College of Pulmonologists. Symposia were also designed  by the American Thoracic Society, American College of Chest Physicians, European Respiratory Society, Cleveland Clinic , International Union against Tuberculosis and Lung Diseases (The Union), Turkish Thoracic Society, Japan anti-TB Association, Bangladesh Primary Care Respiratory Society and Nepalese Respiratory Society and approved  by the Scientific Committee. The scientific programme of NAPCON 2020 commenced on Thursday, 28th January till Sunday, 31st January 2021 from 9 a.m. to 6:30 p.m. on all days and witnessed record attendance of 3548 delegate log-ins on 28th January, 2658 on 29th January, 2588 on 30th January and 1961 on  31st January 2021. The NCCP (I) and ICS orations also witnessed record attendance of 469 and 426 attendees respectively. The best attended session in the scientific programme was the APSR symposium on COVID-19 on 28th January 2021 with 468 attendees.</p>

    <p>A virtual All-India post-graduate quiz under aegis of NCCP(I) was convened by Dr. Nikhil Sarangdhar on 30th January 2021 with Dr. Vishnu Sharma as Quiz Master and Dr. Rajesh Venkat as Co-Quiz Master and was presided over by Dr. S. N. Gaur, Dr. P. D. Motiani and Dr. S. K. Katiyar. Virtual Cultural programmes were broadcast on 28th and 30th January 2021 during the evening. One of the cultural programmes, compiled by Academy of Pulmonary and Critical Care medicine under the leadership of Dr. Jayaprakash B had participation from medical college chest faculty and was very much appreciated by the delegates. NAPCON 2020 also hosted the virtual Governing Council and Annual General Body meetings of National College of Chest Physicians (India) and Indian Chest Society. The highly successful and much-appreciated scientific deliberations of the conference concluded with the valedictory function on Sunday, 31st January 2021.</p>

    <P>22nd NAPCON 2020 surpassed all records of previous conferences with the largest number of registered delegates, attendees, international participation and abstracts submitted for presentation, setting a benchmark for other virtual conferences to follow and creating a milestone for medical conferences in the near future. We humbly thank and acknowledge all delegates, faculty members and our colleagues for their goodwill and participation at the conference, which helped greatly towards making the first-ever Virtual NAPCON a grand success.</P>
</div>

            <button class="read-btn" @click=" showcontentfunction()">Read More →</button>

            </div>

            <div class="images">
<div class="first-img">
<img src="../assets/doctor/sngaur-sir.png">
<p class="text-center">Prof. Dr. S. N. Gaur </p>
<p class="text-center" style="position: relative; margin-top: -12px">Organising Chairman, NAPCON 2020 </p>
</div>
<div class="first-img">
    <img src="../assets/doctor/nikhil-sir.jpg">
    <p class="text-center">Dr. Nikhil Sarangdhar </p>
<p class="text-center" style="position: relative; margin-top: -12px">Organising Secretary, NAPCON 2020</p>
</div>
            </div>
        </div>
    </div>
</template>

<script>
import BannerSection from "@/components/BannerSection.vue";


export default{
    components: {
        BannerSection,
    },

data(){
    return{
        showcontent: false,
    }
},

methods: {
    showcontentfunction(){
        this.showcontent = !this.showcontent;
    }
}
}
</script>

<style scoped>
.content {
    width: 90%;
}
.content p, .hide-content p{
    text-align: justify;
}

.read-btn{
    padding: 10px 25px;
    background: #0d6efd;
    color: #fff;
    border: none;
    outline: none;
     border-radius: 5px;
  }
.napcon{
    display: grid;
    grid-template-columns: 80% 20%;
    padding: 10px;

}
.first-img{
    height: 300px;
    width: 100%;
    margin: 0px 0px;
 }
 .first-img:nth-child(2){
    margin: 100px 0px;
 }
 .first-img img{
    height: 100%;
    width: 100%;
 }
</style>
